import React from "react"
import { Heading, Box, Grid, Text } from "theme-ui"
import Richtext from "../richtext"
import { SpecsList } from "../specs"
import { ImageSlider } from "../slider"
import LabelValue from "../label_value"
import Section from "../section"
import { Hero } from "../hero"
import ImageGallerySmall from "../image_gallery_small_new"
import ImageTextOverlay from "../image_text_overlay"
import { useTranslations } from "../../utils/label_translations_hook"
import SingleImageText from "../single_image_text"
import RelatedContentCards from "../related_content_cards"
import IntroDetails from "../intro_details"
import Cta from "../cta"
import FormEmbed from "../form_embed"
//import { Form } from "../form"

const PreOwned = props => {
  let interiorLabel = useTranslations("interior")
  let specificationsLabel = useTranslations("specifications")
  let infoLabel = useTranslations("additional_information")
  let contactLabel = useTranslations("contact_us")

  // const contact_form_field = [
  //   {
  //     component: "Input",
  //     input_type: "text",
  //     label: "Pre-owned car",
  //     name: "pre-owned-car",
  //     required: false,
  //     display: "none",
  //     default_value: props.blok.title && props.blok.title,
  //     _uid: "car_uid_123",
  //   },
  // ]

  const techniciansNote =
    props.blok &&
    props.blok.tech_note &&
    props.blok.tech_note.bloks.length > 0 &&
    props.blok.tech_note.bloks[0]

  return (
    <main>
      <Hero
        variant="herohome"
        image={props.blok.main_image}
        title={props.blok.tag_line}
        title_position_horizontal={props.blok.title_position_horizontal}
        title_position_vertical={props.blok.title_position_vertical}
        title_type="h2"
        mb="0"
      />
      <IntroDetails title="details" mb={[0, 0, 0]}>
        {props.blok.price_inc_vat && (
          <LabelValue labelText="price" value={props.blok.price_inc_vat} />
        )}
        {props.blok.mileage && (
          <LabelValue labelText="mileage" value={props.blok.mileage} />
        )}
        {props.blok.year_of_registration && (
          <LabelValue
            labelText="year_of_registration"
            value={props.blok.year_of_registration}
          />
        )}
        {props.blok.price_inc_vat_bpm && (
          <LabelValue
            labelText="price_inc_vat_and_bpm"
            value={props.blok.price_inc_vat_bpm}
          />
        )}
        {props.blok.vat_deductible && (
          <LabelValue
            labelText="vat_deductible"
            value={props.blok.vat_deductible}
          />
        )}
        {props.blok.location && (
          <LabelValue labelText="location" value={props.blok.location} />
        )}
      </IntroDetails>
      <Box
        sx={{
          lineHeight: "0",
          mb: [4, null, 5],
          background: "#000",
        }}
      >
        <ImageSlider
          blok={props.blok.slider_images[0]}
          srcsetSizes="(min-width: 1200px) 1200px, (min-width: 992px) 80vw, (min-width: 576px) 100vw, (max-width: 575px) 100vw, 100vw"
          mb="0"
        />
      </Box>

      <Section>
        <Text variant="smallTitle">{useTranslations(props.blok.status)}</Text>
        <Grid variant="titleText">
          <Box mb={[null, null, 5]}>
            <Heading as="h1" mb="4" sx={{ maxWidth: "10ch" }}>
              {props.blok.title}
            </Heading>
          </Box>
          <Box>
            <Richtext text={props.blok.description} sx={{ maxWidth: "80ch" }} />
            {props.blok.contact &&
              props.blok.contact.map(
                (blok, i) =>
                  blok.form_id && (
                    <Cta
                      key={i}
                      blok={{
                        component: "cta_scroll_to",
                        button_text: blok.cta_button_text,

                        // scroll_to_id: blok.name
                        //   .toLowerCase()
                        //   .replace(/\s/g, ""),
                        scroll_to_id: blok.form_id,
                      }}
                      button_type="arrowLink"
                    />
                  ),
              )}
          </Box>
        </Grid>
      </Section>

      {props.blok.interior_description && props.blok.interior_image_1 && (
        <>
          <ImageTextOverlay
            blok={{
              title: interiorLabel,
              image: props.blok.interior_image_1,
              text: props.blok.interior_description,
            }}
          />
          <ImageGallerySmall
            blok={{
              image_1: props.blok.interior_image_2,
              image_2: props.blok.interior_image_3,
              image_3: props.blok.interior_image_4,
            }}
            variant="smallGalleryBlack"
            mb="0"
          />
        </>
      )}
      <Box sx={{ "& > div": { mb: 0 } }}>
        <Section
          sx={{
            py: 5,
            //backgroundColor: "cardSectionBg",
            mb: 5,
            overflow: "visible",
            "& > div": { overflow: "visible" },
          }}
        >
          <Heading mb="4">{specificationsLabel}</Heading>
          <SpecsList
            blok={props.blok.specs_list[0]}
            currentLang={props.blok.lang}
            specs_type="pre_owned"
          />
        </Section>
      </Box>
      {props.blok.technicians_note && (
        <SingleImageText
          blok={{
            ...techniciansNote,
            text: props.blok.technicians_note
              ? props.blok.technicians_note
              : techniciansNote.text,
            background_color: "#000",
            layout: "3",
          }}
        />
      )}

      {/* {props.blok.contact &&
        props.blok.contact.map(blok => (
          <Form blok={blok} key={blok._uid} extra_fields={contact_form_field} />
        ))} */}

      {props.blok.contact &&
        props.blok.contact.map(blok => (
          <FormEmbed blok={blok} key={blok._uid} />
        ))}

      <RelatedContentCards loop={props.blok.loop} title={infoLabel} />
    </main>
  )
}

export default PreOwned
